.buttonContainer {
  margin-top: var(--fs-m);
  margin-bottom: var(--fs-xl);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.rightContainer {
  margin-top: 200px;
  width: 100%;
  padding: var(--fs-m);
  background-color: var(--color-white);

    p {
      color: var(--color-blue);
    }
}