.integration-option-container {
  display: flex;
  flex-direction: column;
  max-height: var(--s-xxxl);
  align-items: center;
  cursor: pointer;
  margin: 0 var(--s-m);
}

.integration-option-icon-wrapper {
  // margin-bottom: var(--s-xs);
  height: calc(var(--s-m) * 3);
  width: calc(var(--s-m) * 3);
  overflow: hidden;
}