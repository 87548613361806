label, .label {
  display: inline-block;
  margin: 0;
  color: var(--color-ink);
  font-family: var(--ui-font-family);
  font-size: var(--fs-xs);
  line-height: var(--s-m);
  font-weight: 400;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  white-space: nowrap;
}

input,
textarea {
  display: inline-block;
  margin: 0;
  height: var(--input-height);
  padding: var(--s-xxs) var(--s-s);
  font-size: var(--fs-b);
  line-height: var(--input-line-height);
  text-align: left;
  border: 0;
  border-radius: var(--border-radius-default);
  outline: 0;
  transition: var(--transition-all);

  color: var(--color-ink);
  background-color: var(--color-grey-light);

  &:focus {
    background-color: var(--color-white);
    box-shadow: inset 0 0 0 1px var(--color-blue-light);
    outline: 0;
  }

  &::placeholder {
    color: var(--color-grey-darker);
  }
}

textarea {
  display: block;
  width: 100%;
  height: 10rem;
  padding: var(--s-xxs) var(--s-s);
  font-size: var(--fs-b);
  border: 0;
  border-radius: var(--border-radius-default);
  outline: 0;
  transition: var(--transition-all);

  color: var(--color-ink);
  background-color: var(--color-grey-light);

  &:focus {
    background-color: var(--color-white);
    box-shadow: inset 0 0 0 1px var(--color-blue-light);
    outline: 0;
  }
  &::placeholder {
    color: var(--color-grey-darker);
  }
}

form,
.form {
  height: 100%;
  display:flex;
  flex-direction: column;

  .field-container,
  .input-error {
    margin-bottom: var(--s-m);
    label:empty {
      display: none;
    }
    display: flex;
    flex-direction: column;

    input {
      width: 100%;
      margin: 0;
    }

    .error {
      display: inline;
      margin-top: var(--s-xxs);
      color: var(--color-orange-dark);
      font-size: var(--fs-s);
      letter-spacing: 0.01em;
    }
  }

  .form-segment {
    padding: var(--s-xs) 0 var(--s-xs) var(--gutter);
    margin-bottom: var(--s-m);
    box-shadow: inset 1px 0 0 var(--color-blue-lighter);
    transition: var(--transition-all);

    &.active,
    &:focus-within {
      box-shadow: inset 1px 0 0 var(--color-orange);
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
  }
}
