.slider {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  height: var(--input-height);
  color: var(--color-ink);

  .horizontal-slider {
    flex: 1 0 0;
    margin: 0 var(--s-s) 0 0;
  }
  .slider-value {
    margin: 0 !important;
    width: var(--s-xxxl);
  }
  input[type=number]::-webkit-outer-spin-button,
  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
     margin: 0;
  }
}
.horizontal-slider {
  height: var(--s-b);
  background-color: var(--color-grey-light);
  border-radius: var(--border-radius-default);
  overflow: hidden;
  cursor: pointer;

  .thumb {
    width: var(--s-xxs);
    height: var(--s-b);
    background-color: var(--color-ink);
    transition: background-color var(--transition-timing);
    &:focus {
      outline: none;
      background-color: var(--color-blue);
    }
  }
  .track {
    background-color: var(--color-grey);
    height: var(--s-b);
    transition: var(--transition-all), width 0.05s;
    &.track-1 {
      background-color: transparent;
    }
  }
}
.horizontal-slider:active,
.horizontal-slider:focus,
.horizontal-slider:focus-within {
  background-color: var(--color-white);
  // box-shadow: inset 0 0 0 1px var(--color-blue-light);
  .thumb {
    outline: none;
    background-color: var(--color-blue);
  }
  .track {
    background-color: var(--color-blue-lighter);
    box-shadow: inset 0 0 0 1px var(--color-blue-light);
    &.track-1 {
      background-color: transparent;
    }
  }
  & + .slider-value input {
    background-color: var(--color-white);
    box-shadow: inset 0 0 0 1px var(--color-blue-light);
    outline: 0;
  }
}
