.container {
  display: grid;
  grid-template-columns: 300px minmax(10px, 1fr);
  grid-gap: var(--s-l);
  align-items: center;
}

.keyStatItems {
  margin: var(--s-b) 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10px, var(--s-xxxl)));
  grid-gap: var(--s-s);
}
.keyStatItem {
}
.keyStatPercentage {
  font-weight: 100;
  font-size: var(--fs-xxxl);
  line-height: 1;
  color: var(--color-ink);
}
.keyStatLabel {
  font-weight: 300;
  font-size: var(--fs-b);
  line-height: 2;
  color: var(--color-ink);
}
.keyStatBar {
  height: var(--s-xxs);
  width: 100%;
  background-color: var(--color-grey-light);
}
