.status {
  display: inline-block;
  width: var(--s-l);
  font-size: 8px;
  text-align: center;
  vertical-align: top;
  color: transparent;

  &.running {
    color: var(--color-green-light);
  }
  &.stopped {
    color: var(--color-orange-light);
  }
  &.unknown,
  &.failed {
    color: var(--color-orange-dark);
  }
  &.pending,
  &.terminating {
    color: var(--color-grey);
  }
}
