.settings-form-container {
  .dropdown-container {
    position: relative;
    width: 100%;
  }

  .dropdown-list {
    z-index: 3;
    position: absolute;
    top: 100%;
    background-color: var(--color-grey-light);

    li {
      padding-left: var(--fs-m);
      padding-right: var(--fs-m);

      &:not(:first-child) {
        padding: 0 var(--fs-m) 0 var(--fs-m);
      }
    }
  }

  .toggle {
    background-color: var(--color-grey-light);

    .dropdown-header-caret {
      transform: rotate(180deg);
    }
  }

  .dropdown-header {
    &-button {
      padding-left: var(--fs-m);
      background: var(--color-grey-light);

      &:hover {
        background: var(--color-grey-light);
      }
    }
  }

  .field-container {
    &:last-child {
      margin-bottom: 0;
    }
  }
}