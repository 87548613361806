.radio-items {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .radio-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 0 0 var(--s-xs) 0;

    input[type='radio'] {
      display: block;
      width: var(--s-b);
      margin-right: var(--s-s);
      visibility: hidden;
      -moz-appearance: initial;
    }

    input[type='radio']:after,
    input[type='radio']:checked:after  {
      content: '';
      top: 7px;
      left: 0;
      position: relative;
      width: var(--s-b);
      height: var(--s-b);
      border-radius: 50%;
      background-color: var(--color-white);
      display: inline-block;
      visibility: visible;
      border: 2px solid var(--color-grey);
      box-shadow: inset 0 0 0 2px var(--color-white);
      transition: background-color var(--transition-timing);
      cursor: pointer;
    }

    input[type='radio']:checked:after {
      background-color: var(--color-blue);
    }

    label {
      color: var(--color-ink);
      text-transform: none;
      font-size: var(--fs-b);
      letter-spacing: 0;
      line-height: var(--input-height);
      cursor: pointer;
    }
  }
}
