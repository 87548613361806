.cardContainer {
  position: relative;
  padding: var(--s-xs) var(--gutter);
  background-color: var(--color-blue-tint);
  border-radius: var(--border-radius-default);

  .cardStatusStrip {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: var(--s-xxs);
    border-radius: var(--border-radius-default) 0 0 var(--border-radius-default) ;

    &.running { background-color: var(--color-green-light);}

    &.stopped { background-color: var(--color-orange-light);}

    &.unknown,
    &.failed { background-color: var(--color-orange-dark);}

    &.pending,
    &.terminating { background-color: var(--color-grey); }
  }

  .dataCardStats {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: var(--s-xs);
  }

  .statsSeparator {
    margin: 0 var(--s-b);
    width: 1px;
    height: var(--s-xl);
    background-color: var(--color-grey-darker);
    transform: skew(-15deg);
  }

  .dataCardPart, .dataCardTotal {
    font-weight: 300;

    & > span:first-child {
      font-size: var(--fs-xxl);
      font-family: var(--ui-font-family);
      font-weight: 200;
      line-height: 1;
      letter-spacing: -0.025em;
      display: block;
    }
  }

  .dataCardNode {
    border-top: 0.5px solid var(--color-blue-lighter);
    padding-top: var(--s-xs);
    span {
      display: block;
    }
    & > span:first-child {
      color: var(--color-blue);
    }
    & > span:last-child {
      font-size: var(--fs-s);
      color: var(--color-blue-light);
    }
  }

  &:hover .cardActionButtons {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }
  .cardActionButtons {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: var(--transition-all);
  }
}

.cardActionButtons {
  display: grid;
  place-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(253,236,210,0.7);
  border-radius: var(--border-radius-default);

  button {
    border-radius: var(--border-radius-circle);
  }
}
