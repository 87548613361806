.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
  display: none;
}

.react-switch-label {
  position: relative;
  display: block;
  width: 40px;
  height: var(--input-height);
  border-radius: 80px;
  position: relative;
  cursor: pointer;
  &:after {
    content: '';
    position: absolute;
    top: 10px;
    left: 0;
    height: var(--s-s);
    width: 100%;
    background: var(--color-grey);
    border-radius: var(--s-xs);
    opacity: 0.5;
    transition: background-color var(--transition-timing);
  }
}

.react-switch-label .react-switch-button,
.react-switch-label:active .react-switch-button {
  position: absolute;
  top: var(--s-xxs);
  left: 0;
  width: var(--s-m);
  height: var(--s-m);
  // width: var(--input-line-height);
  // height: var(--input-line-height);
  border-radius: var(--border-radius-circle);
  transition: var(--transition-all);
  background: var(--color-grey-light);
  box-shadow: 0 0 0 0.5px var(--color-grey),
              0 1px 4px rgba(0,0,0,0.1);
  z-index: 1;
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: var(--s-b);
  right: 0;
  background: var(--color-orange-light);
  box-shadow: 0 0 0 0.5px var(--color-orange),
              0 1px 4px rgba(0,0,0,0.1);
}

.react-switch-checkbox:checked + .react-switch-label:after {
  background: var(--color-orange-light);
}
