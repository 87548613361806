:root {

  // SPACING
  // Use these variables for padding & margin
  --s-xxxl: 128px;
  --s-xxl:  64px;
  --s-xl:   48px;
  --s-l:    32px;
  --s-m:    24px;
  --s-d:    18px;
  --s-b:    16px;
  --s-s:    12px;
  --s-xs:   8px;
  --s-xxs:  4px;

  // TYPOGRAPHY
  // IBM Plex Serif    200 / 300 / 300i
  // IBM Plex Sans     300 / 400
  // IBM Plex Mono     400
  // @import url('https://fonts.googleapis.com/css?family=IBM+Plex+Serif:200,300,300i&display=swap');
  // @import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans:100,200,300,400,500&display=swap');
  @import url('https://fonts.googleapis.com/css?family=IBM+Plex+Mono:100,200,400&display=swap');

  --text-font-family:      "IBM Plex Serif", Helvetica, Arial, sans-serif;
  --ui-font-family:        "IBM Plex Sans", Helvetica, Arial, sans-serif;
  --code-font-family:      "IBM Plex Mono", Helvetica, Arial, sans-serif;
  --line-height-text:      1.5;
  --line-height-heading:   1.2;

  // FONT-SIZES
  // Use these for font-sizes
  --fs-xxxl: 48px;
  --fs-xxl:  32px;
  --fs-xl:   24px;
  --fs-l:    18px;
  --fs-m:    16px;
  --fs-b:    14px;
  --fs-s:    12px;
  --fs-xs:   10px;

  // COLORS
  --color-black:              #000000;
  --color-ink:                #1D2228;
  --color-grey-darker:        #5D636C;
  --color-grey-dark:          #949BA3;
  --color-grey:               #CFD3D8;
  --color-grey-light:         #F3F4F6;
  --color-grey-lighter:       #FAFBFD;
  --color-white:              #FFFFFF;

  --color-blue-darker:        #101859;
  --color-blue-dark:          #002689;
  --color-blue:               #2D48C0;
  --color-blue-light:         #7F97ED;
  --color-blue-lighter:       #D5DEFB;
  --color-blue-tint:          #EDF1FF;

  --color-sky-darker:         #174169;
  --color-sky-dark:           #3F7E9F;
  --color-sky:                #7FCADD;
  --color-sky-light:          #B5F0F1;
  --color-sky-lighter:        #DEF7F7;
  --color-sky-tint:           #F2FDFB;

  --color-green-darker:       #01342E;
  --color-green-dark:         #115640;
  --color-green:              #13894E;
  --color-green-light:        #6CD38C;
  --color-green-lighter:      #CFF9D3;
  --color-green-tint:         #ECFFEE;

  --color-yellow-darker:      #A26509;
  --color-yellow-dark:        #CC9300;
  --color-yellow:             #F4C81B;
  --color-yellow-light:       #F6DD5C;
  --color-yellow-lighter:     #FCF09A;
  --color-yellow-tint:        #FFFCE6;

  --color-orange-darker:      #810E07;
  --color-orange-dark:        #C11414;
  --color-orange:             #EA5F21;
  --color-orange-light:       #F49351;
  --color-orange-lighter:     #FDECD2;
  --color-orange-tint:        #FFF8EE;

  --color-modal-overlay:      rgba(93, 99, 108, 0.5);

  // SIZES
  // Use these to specify fixed size elements
  --container-max-width:   1200px;
  --responsive-medium:     800px;
  --responsive-small:      600px;
  --responsive-smallest:   400px;
  --wizard-width:          640px;
  --dropdown-width:        360px;
  --sidebar-width:         248px;
  --tooltip-width:         248px;
  --vertical-tab-width:    200px;
  --toolbar-width:         160px;
  --toolbar-height:        48px;
  --statusbar-height:      84px;

  // FORMS
  --input-height:          32px;
  --input-line-height:     24px;

  // GRID
  --num-columns: 16;
  --gutter:      16px;

  // TRANSITION
  --transition-all:     all 0.15s ease-in;
  --transition-timing:  0.15s ease-in;

  // BORDER
  // Use these variables for borders
  --border-width-1px:      1px;
  --border-radius-circle:  50% 50%;
  --border-radius-default: 2px;
  --border-radius-medium:  4px;
  --border-radius-large:   12px;
  --border-radius-top:     2px 2px 0 0;
  --border-radius-right:   0 2px 2px 0;
  --border-radius-bottom:  0 0 2px 2px;
  --border-radius-left:    2px 0 0 2px;

  // SHADOW
  --box-shadow-modal: 0 16px 24px rgba(0, 0, 0, 0.1);
  --box-shadow-xl:    0 24px 36px rgba(0, 0, 0, 0.2);
  --box-shadow-l:     0 8px 16px rgba(0, 0, 0, 0.05);
  --box-shadow-b:     0 4px 12px rgba(0, 0, 0, 0.02);
  --box-shadow-xs:    0 0 0 1px var(--color-grey-light);

  --shadow-color: 220deg 5% 72%;
  --shadow-elevation-low:
    0px 0.4px 0.4px hsl(var(--shadow-color) / 0.48),
    0px 1.4px 1.3px -3.6px hsl(var(--shadow-color) / 0.33);
  --shadow-elevation-medium:
    0px 0.4px 0.4px hsl(var(--shadow-color) / 0.67),
    0px 6.8px 6.5px -3.6px hsl(var(--shadow-color) / 0.46);
  --shadow-elevation-high:
    0px 0.4px 0.4px hsl(var(--shadow-color) / 0.63),
    0px 2.6px 2.5px -1.2px hsl(var(--shadow-color) / 0.53),
    0px 9.4px 9px -2.4px hsl(var(--shadow-color) / 0.43),
    -0.1px 26.9px 25.6px -3.6px hsl(var(--shadow-color) / 0.33);
}
