.dropdown-container {
  display: flex;
  flex-direction: column;
  width: var(--dropdown-width);
  max-height: 60vh;
  border-radius: var(--border-radius-default);
  margin-bottom: 0;
  padding-bottom: 0;
  color: var(--color-ink);
  cursor: pointer;
  padding: 0 1px;

  &.dropdown-full-width {
    width: 100%;
  }

  &.form {
    width: 100%;
  }

  .form-dropdown-header {
    padding-left: var(--s-s) ;
  }

  .dropdown-header {
    display: flex;
    align-items: center;
    width: 100%;
    height: var(--input-height);
    background: var(--color-grey-light);
    color: var(--color-ink);
    border-radius: var(--border-radius-default);
    box-shadow: 0 0 0 1px transparent;
    transition: var(--transition-all);

    padding-left: var(--s-s);

    &-info,
    &-caret {
      flex: 0 0 var(--s-l);
      text-align: center;
      line-height: var(--input-height);
      height: var(--input-height);
      width: var(--input-height);
      transition: var(--transition-all);
    }
    &-info {
      color: var(--color-grey-dark);
    }

    &-button {
      flex: 1 1 auto;
      text-align: left;
      text-transform: none;
      color: var(--color-ink);
      padding: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      background: transparent;

      &:hover {
        background: transparent;
      }
    }
  }

  .toggle {
    background-color: var(--color-white);
    box-shadow: 0 0 0 1px var(--color-blue-light);
    border-radius: var(--border-radius-default) var(--border-radius-default) 0px 0px;

    .dropdown-header-caret {
      transform: rotate(180deg);
    }
  }

  .dropdown-list {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: var(--color-white);
    border-radius: 0 0 var(--border-radius-default) var(--border-radius-default);
    box-shadow: 0 0 0 1px var(--color-blue-light);
    list-style: none;
    overflow: auto;

    li {
      display: block;
      text-align: left;
      line-height: var(--input-height);
      color: var(--color-ink);
      transition: var(--transition-all);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      padding-left: var(--s-s);

      &:hover {
        background-color: var(--color-blue-tint);
      }
    }

    .dropdown-list-search {
      position: sticky;
      top: 0;
      padding-left: 0;
    }

    .search-input {
      position: relative;

      .search-icon,
      .search-clear {
        position: absolute;
        top: 0;
        left: 0;
        text-align: center;
        line-height: var(--input-height);
        height: var(--input-height);
        width: var(--input-height);
        color: var(--color-grey-dark);
      }
      .search-icon {
        pointer-events: none;
      }
      .search-clear {
        left: auto;
        right: 0;
      }

      input {
        padding-left: var(--s-l);
        padding-right: var(--s-l);
        width: 100%;
        border-radius: 0;

        &:focus,
        &:active {
          outline: 0;
          box-shadow: 0 0 0 1px var(--color-blue-light);
        }
      }
    }
  }
}

.dropdown-list-wrapper {
  display: flex;
  position: relative;
}