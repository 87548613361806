.container {
  display: block;
}

.fakeFileInputContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  cursor: pointer;
}

.title {
  font-size: var(--fs-xs);
  font-family: var(--ui-font-family);
  font-style: normal;
  font-weight: normal;
  line-height: var(--s-m);
  color: var(--color-ink);
  letter-spacing: var(--line-height-text);
  text-transform: uppercase;
}

.importingDestination {
  margin-top: var(--s-m);
  font-family: var(--ui-font-family);
  font-size: var(--fs-b);
  font-weight: 300;
  line-height: var(--fs-l);
  font-style: normal;
}

.footer {
  margin-top: var(--fs-xxl);
  display: flex;
  justify-content: flex-end;
}

.fakeFileIndicator {
  display: flex;
  flex-grow: 2;
  align-items: center;
  padding-left: 16px;
  width: 250px;
  height: 32px;
  background-color: var(--color-grey-light);
  color: var(--color-grey-dark);
  overflow: hidden;
  white-space: nowrap;
}

.fileInput {
  visibility: hidden;
  position: absolute;
}