@keyframes slideInFade {
  0% { transform: translateX(24px); opacity: 0; }
  100% { transform: translateX(0); opacity: 1; }
}
@keyframes slideDownFade {
  0% { transform: translateY(-24px); opacity: 0; }
  100% { transform: translateY(0); opacity: 1; }
}

.right-panel {
  position: relative;
  width: var(--wizard-width);
  margin: var(--toolbar-height) 0 0 0;
  padding: var(--gutter);
  background-color: var(--color-white);
  box-shadow: var(--box-shadow-xs), var(--box-shadow-l);
  animation: slideInFade 0.15s ease-in;
  z-index: 2;
  overflow: hidden;

  &-header {
    margin: 0 0 var(--s-m) 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .options-bar {
    text-align: right;
  }

  & > div {
    height: 100%;
  }

  h1.header {
    margin: 0 0 var(--s-m) 0;
  }
}

.notification-panel {
  animation: slideDownFade 0.15s ease-in;
  position: fixed;
  top: var(--s-xl);
  right: var(--s-m);
  width: calc(var(--s-xxxl) * 3);
  background-color: var(--color-white);
  box-shadow: var(--box-shadow-xs), var(--box-shadow-l);
  border-radius: var(--border-radius-bottom);
  max-height: 70vh;
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 8;

  .header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    line-height: var(--input-height);
    padding: 0 var(--s-b);
    box-shadow: inset 0 -1px 0 var(--color-grey-light);

    .header {
      color: var(--color-ink);
      font-family: var(--ui-font-family);
      font-size: var(--fs-xs);
      font-weight: 400;
      text-decoration: none;
      text-transform: uppercase;
      letter-spacing: 0.15em;
    }
    .clear-btn {
      margin-left: auto;
    }
  }
}
