.key-stats-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: var(--s-m);

  & > * + * {
    margin-left: var(--s-m);
  }
}
.key-stat {
  display: flex;
  flex-direction: column;
  font-style: normal;

  .key-stat-value {
    font-feature-settings: "ss03";
    font-weight: 200;
    font-size: var(--fs-xl);
    line-height: var(--s-m);
  }

  .key-stat-legend {
    font-size: var(--fs-b);
    line-height: var(--s-m);
    font-weight: 300;
    text-decoration: none;
    text-transform: capitalize;
    color: var(--color-ink);
  }
}
