
.integration-credentials-container {
  display: flex;
  flex-direction: column;
  background-color: var(--color-white);
  box-shadow: var(--box-shadow-xs), var(--box-shadow-b);
  max-width: calc(var(--responsive-small) + var(--responsive-smallest));
  margin: var(--s-l) 0;
}

.integration-credentials-info-wrapper {
  display: inline-block;
}

.integration-credentials-icon-wrapper {
  display: inline-block;
  margin: var(--s-xs);
  height: var(--s-xxl);
  width: var(--s-xxl);
} 

.text-info-wrapper {
  display: flex;
  flex-direction: column;
  font-weight: 300;
  align-items: baseline;
  margin-left: var(--s-s);
  h2 {
    margin: 0;
  }
  span {
    display: inline;
  }
}

.integration-credentials-name {
  display: inline-block;
  margin: 0 var(--s-s)
}

.integration-credentials-input {
  flex-basis: 100%;
  input {
    width: 100%
  }
  label {
    display: flex;
    align-self: center;
    align-items: center;
  }
}

.integration-credentials-header {
  display: flex;
  flex: 1;
  height: 80px;
  padding: var(--s-b);
}

.integration-credentials-actions {
  display: flex;
  flex: 100;
  justify-content: flex-end;
  align-items: flex-end;
}

.integration-credentials-wrapper {
  display: flex;
  flex-direction: column;
  background-color: var(--color-grey-lighter);
  padding: var(--s-b);
}

.info-icon {
  display: inline-block;
  font-size: var(--fs-s);
}

.integration-credentials-input-label {
  display: flex;
}