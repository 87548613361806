.ReactTable {
  border: 0;

  .rt-noData {
    z-index: 0
  }

  .rt-thead .rt-th.-sort-asc,
  .rt-thead .rt-td.-sort-asc {
    box-shadow: inset 0 1px 0 0 var(--color-orange);
  }
  .rt-thead .rt-th.-sort-desc,
  .rt-thead .rt-td.-sort-desc {
    box-shadow: 0 1px 0 0 var(--color-orange);
  }

  .rt-thead.-header {
    box-shadow: none;
  }
  .rt-thead .rt-th {
    font-size: var(--fs-xs);
    line-height: var(--s-m);
    font-weight: 400;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    color: var(--color-ink);
  }

  .rt-resizable-header-content {
    width: 100%;
  }

  .rt-resizable-header-content > span{
    display: block;
    width: 100%;
  }

  .rt-thead .rt-th,
  .rt-tbody .rt-td {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 var(--s-xs);
    line-height: var(--s-m);
    text-align: center;
    border: 0;
  }

  .rt-thead .rt-tr,
  .rt-tbody .rt-tr-group {
    border: 0;
    box-shadow: 0 1px 0 var(--color-grey-light);
  }

  .rt-tr.example {
    background-color: var(--color-yellow-tint);
  }

  .rt-th {
     &:first-child .rt-resizable-header-content > span {
       text-align: left;
     }
     &:last-child .rt-resizable-header-content > span {
       text-align: right;
     }
     .rt-resizable-header-content > span {
       &.name,
       &.text {
         text-align: left;
       }
       &.icon {
         text-align: center;
       }
       &.number,
       &.date {
         text-align: right;
       }
     }
   }
  .rt-td {
    &.name,
    &.text,
    &:first-child {
      text-align: left;
    }
    &.icon {
      text-align: center;
    }
    &.number,
    &:last-child {
      text-align: right;
      justify-content: flex-end;
    }
    &.number,
    &.date {
      justify-content: flex-end;
      font-family: var(--code-font-family);
    }
    .true {
      color: var(--color-green);
    }
    .false {
      color: var(--color-orange-dark);
    }
  }

  .cell-action-buttons {
    display: none;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    right: 0;
  }
  .rt-tr-group:hover {
    .cell-action-buttons {
      display: block;
      z-index: 2;
    }
  }

  .rt-tr-group:hover {
    background-color: var(--color-orange-tint);
  }

  .cell-actions-wrapper {
    align-self: stretch;
    position: relative;
    display: flex;
    justify-content: space-between;
    flex: 1;
    align-items: center;
    cursor: default;
    min-width: 120px;
    width: 100%;

    &.data-nodes {
      justify-content: flex-start;
    }

    .tag {
      margin: 0 var(--fs-m);
    }
  }

  .cell-actions-content {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 80px;
    width: 100%;
    justify-content: space-between;

    .cell-info-icon {
      color: var(--color-grey-dark);
    }
  }
}
