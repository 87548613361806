/* Style the accordion section */
.accordion-section {
  display: flex;
  flex-direction: column;
}

/* Style the div that is used to open and close the accordion panel */
.accordion-header {
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  font-size: var(--fs-m);
  font-weight: 350;
  color: var(--color-blue);
  transition: var(--transition-all);
  &:after {
    content: '+';
    font-size: var(--fs-xl);
    line-height: 0.5;
    margin-left: auto;
  }
  &.active:after {
    content: '–';
    font-size: var(--fs-xl);
    line-height: 0.5;
    margin-left: auto;
  }

  &.active {
    color: var(--color-orange);
    margin-bottom: var(--gutter);

    & + .accordion-content {
      max-height: 2500px;
      transition: max-height var(--transition-timing);
    }
  }
}

.accordion-content {
  max-width: 100%;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s cubic-bezier(0, 1.05, 0, 1);
}
