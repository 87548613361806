@keyframes statusLoader {
  0% {
    content: "—";
  }
  16% {
    content: "\005C";
  }
  50% {
    content: "|";
  }
  83% {
    content: "/";
  }
  100% {
    content: "—";
  }
}

.statusbar {
  width: 100%;
  height: var(--statusbar-height);
  position: fixed;
  display: grid;
  grid-template-columns: var(--dropdown-width) 1fr max-content;
  align-items: center;
  top: var(--toolbar-height);
  left: 0;
  justify-content: space-between;
  align-items: center;
  padding-right: var(--s-b);
  z-index: 2;
  box-shadow: var(--box-shadow-l);
  background-color: var(--color-white);
  border-bottom: 4px solid var(--color-grey);

  &.running {
    border-color: var(--color-green-light);
  }
  &.stopped {
    border-color: var(--color-orange-light);
  }
  &.unknown,
  &.failed {
    border-color: var(--color-orange-dark);
  }
  &.new,
  &.pending,
  &.terminating {
    border-color: var(--color-grey-dark);
  }

  .statusbar-stats-container {
    grid-column: 2/3;
    display: flex;
    gap: var(--s-b);
    justify-content: flex-start;
    align-items: center;
    padding: 0 var(--s-b);

    .statusbar-status {
      font-feature-settings: "ss03";
      font-weight: 200;
      font-size: var(--fs-xl);
      line-height: 1;
      &:before {
        content: '—';
        display: inline-block;
        width: var(--s-xl);
        text-align: center;
        font-family: var(--code-font-family);
        animation: statusLoader 0.2s linear infinite;
      }
    }
  }

  .statusbar-action-buttons {
    grid-column: 3/-1;
  }

  .statusbar-info {
    position: relative;
    padding: var(--s-xs) var(--s-b);
    height: 100%;
    display: grid;
    grid-template-columns: 1fr var(--s-l);
    grid-template-rows: var(--s-l) 1fr;
    justify-items: start;
    align-items: center;
    background-color: var(--color-white);
    border-right: 1px solid var(--color-grey);
    cursor: pointer;
  }

  .statusbar-tags {
    grid-column: 1/2;
    grid-row: 1/2;
    display: flex;
    align-items: center;
  }

  .statusbar-name {
    grid-column: 1/2;
    grid-row: 2/-1;
    display: inline-block;
    margin: 0;
    font-family: var(--ui-font-family);
    font-size: var(--fs-xl);
    font-weight: 300;
    letter-spacing: 0;
    line-height: 1;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .statusbar-caret {
    grid-column: 2/-1;
    grid-row: 2/-1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--s-l);
    height: var(--s-l);
    font-size: var(--fs-xl);
    transition: var(--transition-all);
    &.toggle {
      transform: rotate(-180deg);
    }
  }

  .statusbar-info-icon {
    display: inline-block;
    font-size: var(--fs-m);
    color: var(--color-grey-dark);
  }

  .statusbar-status.tag {
    &.running {
      background-color: var(--color-green-light);
    }
    &.stopped {
      background-color: var(--color-orange-light);
    }
    &.unknown,
    &.failed {
      background-color: var(--color-orange-dark);
    }
    &.new,
    &.pending,
    &.terminating {
      background-color: var(--color-grey-dark);
    }
  }
}

.table-overlay {
  position: fixed;
  top: calc(var(--toolbar-height) + var(--statusbar-height));
  width: 100%;
  height: 100%;
  align-content: stretch;
  flex-wrap: wrap;
  flex-basis: 100%;
  left: 0;
  z-index: 1;
  transition: var(--transition-all);
  backdrop-filter: blur(8px);

  .card {
    border-radius: 0;
  }
}
