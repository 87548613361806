.dropdownHeader {
  display: flex;
  padding-left: var(--s-s);
  background: var(--color-grey-light);
  color: var(--color-ink);
  transition: var(--transition-all);
}

.input {
  display: block;
  line-height: var(--input-height);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  background-color: var(--color-white);
  cursor: pointer;

  &:hover {
    background-color: var(--color-blue-tint);
  }
}

.input:focus {
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  caret-color: transparent;
}
