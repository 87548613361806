.table-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: var(--s-b);
  &-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
table {
  table-layout: fixed;
  width: 100%;
  border: 0;
  border-collapse: collapse;
  overflow-x: auto;

  display: grid;
  border-collapse: collapse;
  min-width: 100%;
  grid-flow-columns: minmax(200px, 1.5fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr);

  thead,
  tbody,
  tr {
    display: contents;
  }

  th, td {
    height: 40px;
    padding: var(--s-xs);
    border-bottom: 1px solid var(--color-grey-light);
  }

  .name,
  th:first-child,
  td:first-child {
    text-align: left;
  }
  .icon {
    text-align: center;
  }
  .number,
  th:last-child,
  td:last-child {
    text-align: right;
  }

  th {
    font-size: var(--fs-xs);
    line-height: var(--s-m);
    font-weight: 400;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    color: var(--color-ink);
  }
  tbody {
    .number,
    .date {
      font-family: var(--code-font-family);
    }
  }
}
