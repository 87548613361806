button.btn {
  display: inline-block;
  margin: 0;
  height: var(--input-height);
  min-width: calc(2 * var(--s-l));
  padding: var(--s-xxs) var(--s-b);
  line-height: var(--input-line-height);
  font-size: var(--fs-b);
  text-align: center;
  border: 0;
  border-radius: var(--border-radius-default);
  outline: 0;
  transition: var(--transition-all);
  cursor: pointer;

  color: var(--color-blue);
  background-color: var(--color-white);

  &.transparent {
    background-color: transparent;
  }

  &:hover {
    background-color: var(--color-grey-light);
  }

  &.danger {
    color: var(--color-orange-dark);
  }

  &.primary {
    color: var(--color-white);
    background-color: var(--color-blue);
    &:hover {
      background-color: var(--color-blue-dark);
    }
    &.danger {
      color: var(--color-white);
      background-color: var(--color-orange-dark);
      &:hover {
        background-color: var(--color-orange-darker);
      }
    }
  }

  &.secondary {
    color: var(--color-blue);
    background-color: var(--color-white);
    &:hover {
      background-color: var(--color-grey-light);
    }
    &.danger {
      color: var(--color-orange-dark);
    }
  }

  &.outlined {
    box-shadow: inset 0 0 0 1px var(--color-blue-lighter);
    // &.danger {
    //   box-shadow: inset 0 0 0 1px var(--color-orange-light);
    // }
  }

  &.link {
    background-color: transparent;
    &:hover {
      color: var(--color-blue-darker);
      background-color: transparent;
    }
    &.danger {
      &:hover {
        color: var(--color-orange-darker);
      }
    }
  }

  &.icon {
    padding: 0;
    width: var(--s-l);
    min-width: var(--s-l);
  }

  & > *:first-child {
    margin-right: var(--s-xs);
    display: inline-block;
    transition: var(--transition-all);
  }
  & > *:last-child {
    margin-right: 0;
    display: inline-block;
    transition: var(--transition-all);
    margin: 0 auto;
  }

  & + button {
    margin-left: var(--s-xs);
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  label::first-letter {
    text-transform: uppercase;
  }

  .hidden-text {
    height: 0px;
    opacity: 0;
    margin: 0;
  }

  &.loading {
    display: flex;
    flex-direction: column;
  }

  &.dropdown {
    color: var(--color-grey-darker);
  }
}
