.action-dropdown {
  position: relative;

  button {
    & + button {
      margin-left: 1px;
    }
    &.action {
      border-radius: 0;
    }
    &:nth-child(2) {
      border-radius: 0;
    }
  }

  .dropdown {
    position: absolute;
    top: calc(var(--input-height) + 1px);
    left: 0;
    margin: -1px 0 0 0;
    padding: var(--s-xs) 0;
    min-width: 100px;
    width: 100%;
    list-style-type: none;
    background-color: var(--color-white);
    box-shadow: 0 0 0 1px var(--color-grey-light);
    border-radius: var(--border-radius-default) 0 var(--border-radius-default) var(--border-radius-default);
    z-index: -1;

    & > li {
      display: block;
      margin: 0;
      padding: 0 var(--s-b);
      line-height: var(--input-height);
      color: var(--color-blue);
      cursor: pointer;

      &:hover {
        color: var(--color-orange);
      }
      &:last-child {
        color: var(--color-orange-dark);
      }
    }
  }

  .hidden {
    display: none;
  }
}
.action-dropdown.open {
  .toggler {
    border-radius: 0 var(--border-radius-default) 0 0;
    background-color: var(--color-white);
    color: var(--color-blue);
    box-shadow: 1px -1px 0 var(--color-grey-light);

    span {
      transform: rotate(180deg);
    }
  }
}
