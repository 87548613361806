.form-group {
  display: flex;

  .field-container {
    flex-grow: 1;
  }
  .primary.btn {
    border-radius: var(--border-radius-right);
    min-width: auto;
  }
}

#google-sign-in-btn {
  width: 100%;
  margin: var(--s-l) 0;
}

.login {
  .react-tabs {
    margin: 0;
    ul {
      margin-top: var(--s-b);
    }
  }
  #react-tabs-1 {
    form {
      display: grid;
      grid-gap: var(--gutter);
      grid-template-columns: 1fr 1fr;
    }
    .field-container {
      margin: 0;
    }
    .footer {
      grid-column: 1/-1;
    }
  }
}

.login-limited {
  border-radius: var(--border-radius-top);
  margin: calc(-1 * var(--s-b)) calc(-1 * var(--s-b)) 0 calc(-1 * var(--s-b));
  padding: var(--s-b);
  background-color: var(--color-blue);
  color: var(--color-white);
  h2 {
    margin: 0;
    font-family: var(--ui-font-family);
    font-weight: 200;
    font-size: var(--fs-xxl);
  }
  p {
    font-weight: 400;
  }
}

#hubspotForm {
  .hs_submit.hs-submit .actions {
    padding: 0;
  }
  input {
    margin: 0;
  }
}
