.modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--color-modal-overlay);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.modal {
  display: flex;
  flex-direction: column;
  width: 480px;
  min-height: var(--s-xxxl);
  padding: var(--s-b) var(--s-m) var(--s-m) var(--s-m);
  background-color: var(--color-white);
  border-radius: var(--border-radius-default);
  box-shadow: var(--box-shadow-modal);

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--s-m);
  }
  &-footer,
  .footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: var(--s-m);
  }
  &-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  h1 {
    margin: 0;
    &::first-letter {
      text-transform: uppercase;
    }
  }
  .danger:not(button) {
    color: var(--color-orange-dark);
  }
  .large-p {
    font-size: var(--fs-l);
  }
}
