.toolbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: var(--toolbar-height);
  padding: 0 var(--s-b);
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: var(--transition-all);
  background: linear-gradient(90deg, var(--color-blue-dark) 41.67%, var(--color-blue-darker) 77.6%);
  z-index: 10;
}

.toolbar-navigation, .toolbar-user {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.toolbar-user {
  margin-left: auto;
}

.toolbar-navigation #logo {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0 var(--s-xl) 0 0;
  .symbol {
    svg {
      fill: var(--color-white);
      height: 24px;
    }
  }
  .type {
    svg {
      fill: var(--color-white);
      height: 13px;
    }
  }
}

.toolbar a,
#toolbar-logout,
#toolbar-notifications {
  position: relative;
  padding: 0 var(--s-b) 0 0;
  color: var(--color-white);
  display: inline-flex;
  align-items: center;
  font-size: var(--fs-b);
  line-height: var(--s-xl);
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;
  transition: var(--transition-all);

  &:link,
  &:active,
  &:visited {
    color: var(--color-white);
  }
  &:hover {
    color: var(--color-white);
    background-color: rgba(255,255,255,0.1);
  }
  &.active {
    color: var(--color-blue);
    background-color: var(--color-white);
  }
  &.disabled-link {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .icon {
    display: inline-block;
    flex: 0 0 var(--s-xl);
    width: var(--s-xl);
    font-size: var(--fs-m);
    text-align: center;
  }
}
.toolbar-user a,
#toolbar-logout,
#toolbar-notifications  {
  padding: 0;
}

.logged-in-as {
  padding: 0 var(--s-s);
  color: var(--color-blue-light);
  a {
    padding: 0;
  }
}

.toolbar-help-block {
  margin-left: auto;
}
