.react-tabs {
  margin-bottom: var(--s-m);
  & > ul {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
    margin: var(--s-m) 0;
    padding: 0;
    box-shadow: 0 1px 0 var(--color-grey-light);
    li {
      outline: 0;
      height: var(--input-height);
      padding: 4px var(--s-b);
      // margin-bottom: -1px;
      line-height: var(--input-line-height);
      font-family: var(--ui-font-family);
      font-size: var(--fs-xs);
      font-weight: 400;
      text-decoration: none;
      text-transform: uppercase;
      letter-spacing: 0.15em;
      color: var(--color-blue);
      transition: var(--transition-all);
      border-bottom: 1px solid transparent;
      &:hover {
        color: var(--color-blue-darker);
      }
      &[aria-selected=true] {
        border-bottom: 1px solid var(--color-orange);
        color: var(--color-orange);
      }
      &[aria-disabled=true] {
        cursor: pointer;
      }
    }
  }
}

.react-tabs-vertical {
  margin-bottom: var(--s-m);
  text-decoration: none;

  .flex-row {
    display: flex;
    flex-direction: row;
  }

  .nav {
    width: var(--vertical-tab-width);
    margin-left: 100px;
    text-align: left;
  }

  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 0 0 0 var(--color-grey-light);
    li {
      height: var(--input-height);
      padding: 4px var(--s-b);
      line-height: var(--input-line-height);
      font-family: var(--ui-font-family);
      font-size: var(--fs-xs);
      font-weight: 400;
      text-decoration: none;
      text-transform: uppercase;
      letter-spacing: 0.15em;
      color: var(--color-blue);
      transition: var(--transition-all);
      &:hover {
        color: var(--color-blue-darker);
      }
      &[aria-selected=true] {
        box-shadow: 0 0 0 var(--color-orange);
        color: var(--color-orange);
      }
      &[aria-disabled=true] {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
}
