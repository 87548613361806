.createdText {
    display: block;
    width: 100%;
    text-align: right;
}

.iconPositive {
    color: var(--color-green-light);
}

.iconNegative {
    color: var(--color-grey);
}