.btn-password-toggle {
  position: absolute;
  top: 0;
  right: 0;
  height: var(--s-l);
  padding: 0 var(--s-xs);
  display: flex;
  align-items: center;
  border: none;
  color: var(--color-grey-dark);
  cursor: pointer;
  transition: var(--transition-timing);

  &:hover,
  &.active {
    color: var(--color-blue);
  }

  &:focus {
    outline: none;
  }
}

.password-field__wrap {
  position: relative;

  input {
    padding-right: var(--s-xl);
  }
}
