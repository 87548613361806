.grid {
  @media screen and (min-width: 720px) {
    display: grid;
    grid-template-columns: 1px repeat(12, minmax(0, 1fr)) 1px;
    grid-gap: var(--s-l);
  }

  @media screen and (max-width: 719px) {
    display: block;
    padding: var(--s-l);
    > * {
      display: block;
      margin-bottom: var(--s-l);
    }
  }
}

.page {
  width: 100%;
  flex-grow: 1;
  align-items: center;
  background: url(../../../assets/vector/bg-rain.svg) no-repeat top right;
  &:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--s-xs);
    background: linear-gradient(90deg, var(--color-blue-dark) 41.67%, var(--color-blue-darker) 77.6%);
  }
}

.left {
  grid-column: 3/9;
  padding-right: var(--s-xl);
  padding-bottom: var(--s-xl);
}

.right {
  grid-column: 9/13;
  max-width: 480px;
}


.textWrapper {
  svg {
    height: 72px;
    width: auto;
  }
  & > h1 {
    @extend h1;
    margin: var(--s-l) 0;
    color: var(--color-blue);
    font-family: var(--ui-font-family);
    font-size: calc(1.25 * var(--fs-xxxl));
    line-height: 1.1;
  }
  & > h2 {
    position: relative;
    margin-top: var(--s-xl);
    font-family: var(--ui-font-family);
    font-size: var(--fs-s);
    line-height: 1;
    font-weight: 500;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    &:after {
      content: '';
      display: block;
      width: var(--s-xl);
      height: 0;
      border-bottom: 2px solid var(--color-orange);
      position: absolute;
      top: var(--s-xxs);
      left: calc(-1 * var(--s-xxl));
    }
  }
  & > p {
    font-size: var(--fs-m);
  }
}

.copyright {
  display: block;
  margin: var(--s-m) 0 0 0;
  font-size: var(--s-s);
  text-align: center;
  color: var(--color-grey-dark);
}

:global {
  .login {
    .form-group {
      align-items: center;
    }
  }
}
