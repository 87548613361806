.sources-form {

  .dropdown-container {
    width: 100%;
    
    .dropdown-header-button{
        color: var(--color-grey-dark);
    }

    .dropdown-list li {
      padding-left: var(--s-s);
    }
  }
}