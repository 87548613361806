.request-password-reset {
  .form-group {
    display: flex;
    align-items: flex-start;
    margin-bottom: var(--s-m);
  
    .input-reset {
      width: 100% 
    }
  
    .field-container {
      flex-grow: 1;
      margin-bottom: 0;
    }
  
    .primary.btn {
      border-radius: 0 var(--border-radius-default) var(--border-radius-default) 0;
      min-width: calc(2 * var(--s-xl));
    }
  }
}

.form__description {
  margin: 0;
  font-weight: 300;
}
