.dashboard-wrapper {
  padding-top: var(--s-xxxl);

  h2 {
    margin: 0;
    font-family: var(--ui-font-family);
    font-size: var(--fs-l);
    font-weight: 300;
    line-height: 1;
  }
  
  .dashboard-widget-group {
    display: grid;
    grid-template-columns: repeat(2, minmax(10px, 1fr));
    grid-auto-rows: 144px;
    grid-gap: var(--s-m);

    .widget-wrapper {
      grid-row: span 3;
      display: grid;
      grid-template-rows: max-content auto;
      grid-gap: var(--s-m);
      .no-data {
        margin: 0;
      }
    }
  }

  .legend-wrapper {
    margin-top: var(--s-b);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    gap: var(--s-l);
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: row;
      gap: var(--s-b);
    }
  }
  .legend-item {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    font-size: var(--fs-b);
    > span:first-child {
      display: block;
      margin-right: var(--s-xs);
      width: var(--s-xs);
      height: var(--s-xs);
      border-radius: var(--border-radius-circle);
    }
  }

  .ReactTable {
    max-height: 75vh;

    .number .tag {
      font-family: var(--code-font-family);
      font-weight: 600;
    }
  }

  .rv-xy-plot__grid-lines__line {
    stroke: var(--color-grey-dark);
    stroke-width: 0.2;
  }
  .rv-xy-plot__axis__ticks {
    font-family: var(--code-font-family);
    font-size: var(--fs-xs);
    font-weight: 400;
    letter-spacing: 0.05em;
  }
  .rv-xy-plot__series--line {
    fill: transparent;
    color: transparent;
  }
  .timeseries-chart .rv-xy-plot__series.rv-xy-plot__series--label > text {
    font-family: var(--code-font-family);
    font-size: var(--fs-xs);
    font-weight: 400;
    letter-spacing: 0.05em;
    &:not(:last-child) {
      display: none;
    }
  }
}

.loader-container {
  padding: var(--s-xxl) var(--s-s);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
