@keyframes Toastify__slideInUp {
  from {
    transform: translateY(50%);
    opacity: 0;
    visibility: visible;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.Toastify__toast-container {
  padding: 0;
  width: 360px;
}
.Toastify__toast {
  min-height: var(--s-l);
  padding: var(--s-xs) var(--s-b);
  margin-bottom: var(--s-xs);
  display: flex;
  align-items: center;
  box-shadow: var(--box-shadow-l);
  border-radius: var(--border-radius-medium);
  animation-name: Toastify__slideInUp;
  animation-duration: 0.1s !important;
  animation-timing-function: ease-out;
  animation-fill-mode: both;
}
.Toastify__toast-body {
  font-family: var(--ui-font-family);
  font-size: var(--fs-s);
}
.Toastify__close-button {
  align-self: center;
  font-weight: normal;
  opacity: 1;
}
.Toastify__progress-bar {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 1;
}

.Toastify__toast--success {
  background: var(--color-green-tint);
  color: var(--color-green-dark);
  border: thin solid var(--color-green-lighter);
  .Toastify__progress-bar {
    background-color: var(--color-green-lighter);
  }
}
.Toastify__toast--error {
  background: var(--color-orange-tint);
  color: var(--color-orange-dark);
  border: thin solid var(--color-orange-lighter);
  .Toastify__progress-bar {
    background-color: var(--color-orange-lighter);
  }
}
