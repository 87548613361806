.container {
  flex: 1 1 auto;
  margin-top: calc(var(--toolbar-height));
  padding: var(--s-m);
  transition: var(--transition-all);
}
.wizard {
  margin-top: calc(var(--toolbar-height));
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex: 1 0 0;

  .form {
    background-color: var(--color-white);
    width: var(--wizard-width);
    flex: 0 0 var(--wizard-width);
    padding: var(--s-xxl) var(--gutter) var(--gutter) var(--gutter);
  }
}
.full-width {
  margin-top: calc(var(--toolbar-height));
  flex: 1 0 0;
  height: auto;
  position: relative;
}
