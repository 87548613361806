.no-data {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: var(--s-xl) auto;
  color: var(--color-grey-dark);

  .icon {
    color: var(--color-grey);
    font-size: var(--fs-xl);
  }
  p {
    margin: 0;
  }
}
