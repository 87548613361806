.add-flow {
  .dropdown-container {
    width: 100%;

   .dropdown-list {

      li {
        padding: 0;
      }
   } 
    
    .dropdown-header {
      width: 100%;
      margin: 0;
      padding-right: 0;

      &-icons {
        display: flex;
        margin-left: auto;
        & > .icon {
          font-size: var(--fs-b);
          padding-right: 0;
          path {
            fill: var(--color-ink);
          }
        }
      }
      &-button {
        color: var(--color-grey-dark);
      }
    }
  }
}
