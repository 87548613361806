.container {
  padding-bottom: var(--fs-xl);
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0 var(--fs-xxl);
  grid-template-areas:
    ". .";
}

.divider {
  height: 1px;
  width: 100%;
  background-color: #CFD3D8;
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--fs-xl);
}

.textareaField {
  margin-bottom: var(--s-m)
}

