.container {
  .info-container {
    margin: var(--s-xl) 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &.navigation {
      margin-top: calc(2 * var(--toolbar-height));
    }

    .key-stats-wrapper {
      margin: 0;
    }
  }
  
  .info-text-wrapper {
    color: var(--color-blue);
    max-width: 50%;

    h1 {
      color: var(--color-blue);
      margin: 0;
    }
  }
}
