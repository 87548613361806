.table-pagination {
  display: flex;
  justify-content: center;
  margin: var(--s-l) 0 0 0;
  padding: 0 var(--gutter);

  &> *:first-child {
    margin-right: var(--s-l);
  }
  &> *:last-child {
    margin-left: var(--s-l);
  }
  .active {
    pointer-events: none;
    color: var(--color-orange);
  }

  button[disabled] {
    opacity: 0;
  }
}