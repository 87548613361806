/* Customize the label (the container) */
.checkbox-container {
  display: block;
  position: relative;
  margin-bottom: var(--s-s);
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: var(--s-s);
  width: var(--s-s);
  background-color: var(--color-grey-light);
  border-radius: var(--border-radius-default);
}

.checkbox-container:hover input ~ .checkmark {
  background-color: var(--color-grey);
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: var(--color-blue);
}