.accountContainer {
  width: 100%;
  padding-top: var(--fs-xxxl);
  display: grid;
  grid-template-columns: minmax(8px, 1fr) 480px;
  grid-template-rows: auto 1fr;
  gap: 0 var(--fs-xxl);
}

.leftContainer {
  padding-left: var(--fs-xl);

  h1, p {
    color: var(--color-blue);
  }
}
