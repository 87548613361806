.notification {
  display: flex;
  flex-direction: row;
  font-style: normal;
  align-items: flex-start;
  width: 100%;
  padding: var(--s-xs) var(--s-b);

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-grey-light);
  }

  .notification-icon {
    margin-right: var(--s-b);
  }
  .notification-details {
    display: flex;
    flex-direction: column;
    letter-spacing: 0.025em;

    .message {
      font-size: var(--fs-b);
      line-height: var(--fs-m);
    }
    .date {
      margin-top: var(--s-xxs);
      font-size: var(--fs-s);
      line-height: var(--fs-s);
      color: var(--color-grey-dark);
    }
  }

  .success {
    color: var(--color-green);
  }
  .error {
    color: var(--color-orange-dark);
  }
}
