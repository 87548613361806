html,
body {
  overscroll-behavior: none;
  scroll-behavior: smooth;
}
body {
  position: relative;
  display: block;
  min-height: 100vh;
  font-size: var(--fs-b);
  font-family: var(--ui-font-family);
  background: var(--color-grey-light);
  color: var(--color-ink);
  -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

  #main {
    display: contents;
  }
  #content {
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
}
h1, h2, h3 {
  font-family: var(--code-font-family);
  letter-spacing: -0.025em;
}
h1 {
  font-size: var(--fs-xxl);
  font-weight: 100;
  margin: var(--s-m) 0;
}
h2, h3 {
  font-weight: 200;
}
p {
  font-weight: 300;
  line-height: 1.6;
}

a {
  text-decoration: none;
  color: var(--color-blue);
  &:hover {
    color: var(--color-blue-dark);
  }
}
img {
  max-width: 100%;
}

@keyframes emptyBar {
  0%{background-position:50% 0%}
  50%{background-position:51% 100%}
  100%{background-position:50% 0%}
}
.empty-bar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: var(--s-xs);
  background: linear-gradient(180deg, var(--color-green-light), var(--color-grey-lighter));
  background-size: 200% 200%;
  animation: emptyBar 3s ease infinite;
}

.card {
  background-color: var(--color-white);
  box-shadow: var(--box-shadow-xs), var(--box-shadow-b);
  border-radius: var(--border-radius-default);
  padding: var(--s-b);

  > *:first-child {
    margin-top: 0;
  }
  > *:last-child {
    margin-bottom: 0;
  }
}
.tag {
  display: inline-block;
  min-width: var(--s-xl);
  padding: 0 var(--s-xs);
  text-align: center;
  font-family: var(--ui-font-family);
  font-size: var(--fs-xs);
  line-height: calc(var(--s-d) + 1px);
  height: var(--s-d);
  font-weight: 500;
  text-decoration: none;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  background-color: var(--color-grey-dark);
  color: var(--color-white);
  border-radius: var(--s-s);

  & + .table-tag {
    margin-left: var(--s-xs);
  }

  &.green {
    background-color: var(--color-green-light);
  }
  &.red {
    background-color: var(--color-orange-dark);
  }
  &.orange {
    background-color: var(--color-orange-light);
  }
  &.yellow {
    background-color: var(--color-yellow);
    color: var(--color-yellow-darker);
  }
  &.system {
    background-color: var(--color-blue-light);
  }
}

.capitalize {
  text-transform: capitalize;
}
